<template>
  <div id="cart" class="cart-mini">
    <!-- title must be not empty to display mini cart header -->
    <SfSidebar
      :visible="isCartSidebarOpen"
      title=" "
      class="sf-sidebar--right"
      @close="toggleCartSidebar"
      position="right"
    >
      <template #circle-icon="{ close }">
        <div class="close-icon desktop-only" @click="close">
          <nuxt-img src="/svg/close-icon.svg" :alt="$gt('Close')" />
        </div>
      </template>
      <transition name="sf-collapse-top" mode="out-in" v-if="visible">
        <div class="notifications">
          <SfNotification
            v-if="!isLoaderVisible"
            :visible="visible"
            :title="$gt('Are you sure?')"
            :message="
              $gt('Are you sure you want to remove this item from your cart?')
            "
            type="secondary"
          >
            <template #action>
              <div class="button-wrap">
                <SfButton
                  class="sf-button_remove_item"
                  @click="actionRemoveItem(tempProduct)"
                >
                  {{ $gt('Yes') }}
                </SfButton>
                <SfButton @click="visible = false">
                  {{ $gt('Cancel') }}
                </SfButton>
              </div>
            </template>
            <template #close>
              <div />
            </template>
            <template #icon>
              <SfIcon style="display: none" />
            </template>
          </SfNotification>
        </div>
      </transition>
      <SfLoader v-if="isLoaderVisible" :loading="isLoaderVisible">
        <div />
      </SfLoader>
      <template #content-top>
        <div class="mobile-content-top">
          <SfProperty
            v-if="totalItems"
            class="sf-property--large cart-summary desktop-only"
            :name="$gt('Your cart')"
            :value="totalItems"
          />
          <div class="close-icon smartphone-only" @click="toggleCartSidebar">
            <nuxt-img src="/svg/close-icon.svg" :alt="$gt('Close')" />
          </div>
        </div>
      </template>
      <transition name="sf-fade" mode="in-out">
        <div v-if="totalItems && products" key="my-cart" class="my-cart">
          <div class="collected-product-list">
            <div
              v-for="(product, productIndex) in products"
              :key="`cart-sidebar-product-${productIndex}`"
              class="cart-item-list"
              @click:remove="sendToRemove({ product })"
            >
              <div class="cart-item-media">
                <div class="cart-item-labels">
                  <div
                    class="cart-item-labels__label"
                    v-if="cartGetters.productHasSpecialPrice(product)"
                  >
                    {{ $gt('Sale') }}
                  </div>
                </div>
                <figure>
                  <nuxt-img
                    :src="
                      cartGetters.getItemImage(product)
                        ? cartGetters.getItemImage(product)
                        : '/icons/placeholder.svg'
                    "
                    alt="Image"
                  />
                </figure>
              </div>
              <div class="cart-item-details">
                <div class="cart-item-content">
                  <div class="cart-item-sku">
                    <p>{{ product.product.merchant_sku }}</p>
                  </div>
                  <nuxt-link :to="localePath(`/${product.product.url_key}`)">
                    <h3 class="cart-item-name">
                      {{ cartGetters.getItemName(product) }}
                    </h3>
                  </nuxt-link>
                  <div>
                    <div
                      v-if="cartGetters.productHasSpecialPrice(product)"
                      class="cart-item-price"
                    >
                      <p class="cart-item-price__special">
                        {{ getSpecialPrice(product) }}
                      </p>
                      <p class="cart-item-price__old">
                        {{ getRegularPrice(product) }}
                      </p>
                    </div>
                    <div v-else class="cart-item-price">
                      <p class="cart-item-price__special">
                        {{ getRegularPrice(product) }}
                      </p>
                    </div>
                  </div>
                  <div class="cart-item-configuration">
                    <div v-if="getAttributes(product).length > 0">
                      <SfProperty
                        v-for="(attr, index) in getAttributes(product)"
                        :key="index"
                        :name="$gat(attr.option_label)"
                        :value="$gat(attr.value_label)"
                      />
                    </div>
                  </div>
                </div>
                <div class="cart-item-button">
                  <div class="sf-collected-product__quantity-wrapper">
                    <CustomQuantitySelector
                      :disabled="
                        loading ||
                        (product.product.is_virtual &&
                          !product.product.is_giftcard)
                      "
                      :quantity="product.quantity"
                      :min-quantity="1"
                      :key="product.uid"
                      v-model="product.quantity"
                      class="sf-collected-product__quantity-selector"
                      @change="
                        (qty, errorMessage) =>
                          updateCartItemQty(product, qty, errorMessage)
                      "
                    />
                  </div>
                  <div class="cart-item-remove">
                    <a @click="sendToRemove({ product })">
                      <Trash />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="!totalItems || !products"
          key="empty-cart"
          class="empty-cart"
        >
          <div class="empty-cart__banner">
            <SfHeading
              :title="$gt('Your shopping cart is empty')"
              :level="2"
              class="empty-cart__heading"
              :description="
                $gt(
                  `It looks like you haven't added any items to the bag yet. Start shopping to fill it out.`
                )
              "
            />
          </div>
        </div>
      </transition>
      <template #content-bottom>
        <transition name="sf-fade">
          <div v-if="totalItems">
            <FreeShipping v-if="locale === 'it'" />
            <CustomButton
              style="margin-top: 1rem"
              specific-width="100%"
              @click="goToCart"
              theme="black"
              :disabled="showErrorMessage"
              :has-icon="true"
              :icon-text="$gt('Your cart')"
              icon="AddToCartIcon"
            >
            </CustomButton>
          </div>
          <div v-else>
            <CustomButton
              specific-width="100%"
              @click="toggleCartSidebar"
              theme="black"
            >
              {{ $gt('Back to shopping') }}
            </CustomButton>
          </div>
        </transition>
      </template>
    </SfSidebar>
  </div>
</template>

<script>
import {
  SfLoader,
  SfNotification,
  SfSidebar,
  SfHeading,
  SfButton,
  SfProperty,
  SfIcon,
} from '@storefront-ui/vue';
import {
  computed,
  defineComponent,
  ref,
  useRouter,
  useContext,
} from '@nuxtjs/composition-api';
import { cartGetters, useExternalCheckout } from '@gemini-vsf/composables';
import {
  useUiState,
  useUiNotification,
  useUrlHelpers,
  useCart,
  useUser,
  useGtm,
  useTranslation,
} from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useAddToCart } from '~/helpers/cart/addToCart';
import {
  CustomButton,
  CustomQuantitySelector,
} from '~/components/General/FormElements';
import FreeShipping from '~/components/Checkout/FreeShipping.vue';
import { Trash } from '~/components/General/Icons';
import formatCurrency from '~/helpers/formatCurrency';

export default defineComponent({
  name: 'CartSidebar',
  components: {
    FreeShipping,
    SfLoader,
    SfNotification,
    SfSidebar,
    SfIcon,
    CustomButton,
    SfHeading,
    SfProperty,
    CustomQuantitySelector,
    SfButton,
    Trash,
  },

  setup() {
    const { locale } = useI18n();
    const { initializeCheckout } = useExternalCheckout();
    const { isCartSidebarOpen, toggleCartSidebar } = useUiState();
    const showErrorMessage = ref(false);
    const router = useRouter();
    const { app } = useContext();
    const {
      cart,
      cartItems,
      removeItem,
      updateItemQty,
      loading,
      error: cartError,
    } = useCart();
    const { showCartError } = useAddToCart();
    const { isAuthenticated } = useUser();
    const { send: sendNotification, notifications } = useUiNotification();
    const { removeFromCartPush } = useGtm();
    const { buildProductUrl } = useUrlHelpers();
    const { $gt, $gat } = useTranslation('translations');
    const totals = computed(() => cartGetters.getTotals(cart.value));
    const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
    // Bug on cart sometimes return null configurable_options, filter out null values
    const getAttributes = (product) => {
      if (product?.configurable_options) {
        const attributes = [];
        product?.configurable_options.forEach((attribute) => {
          if (attribute && attribute?.option_label && attribute?.value_label) {
            attributes.push(attribute);
          }
        });
        return attributes;
      }
      return [];
    };
    const visible = ref(false);
    const isLoaderVisible = ref(false);
    const tempProduct = ref();
    const quantitySelectorKey = ref();
    const updatingQuantity = ref(false);

    const productSKU = ({ product }) => product?.value?.merchant_sku;

    const goToCheckout = async () => {
      const redirectUrl = await initializeCheckout({
        baseUrl: '/checkout/user-account',
      });
      await router.push(`${app.localePath(redirectUrl)}`);
    };

    const goToCart = () => {
      if (!showErrorMessage.value) {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        setTimeout(async () => {
          await router.push(`${app.localePath('/cart')}`);
          toggleCartSidebar();
        }, 500);
      }
    };

    const sendToRemove = ({ product }) => {
      if (notifications.value.length > 0) {
        notifications.value[0].dismiss();
      }

      visible.value = true;
      tempProduct.value = product;
    };

    const actionRemoveItem = async (product) => {
      isLoaderVisible.value = true;

      await removeItem({ product });
      removeFromCartPush(product);

      isLoaderVisible.value = false;
      visible.value = false;

      sendNotification({
        id: Symbol('product_removed'),
        message: `${cartGetters.getItemName(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          product
        )} ${$gt('successfully removed from your cart')}`,
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'Product removed',
      });
    };

    const updateCartItemQty = async (product, qty, hasErrorMessage) => {
      showErrorMessage.value = hasErrorMessage;
      updatingQuantity.value = true;
      if (!showErrorMessage.value) {
        await updateItemQty({ product }, qty);
      }
      if (cartError.value.updateItemQty) {
        showCartError(cartError.value.updateItemQty);
      }
      quantitySelectorKey.value = Date.now();
      updatingQuantity.value = false;
      if (showErrorMessage.value) {
        setTimeout(() => {
          showErrorMessage.value = false;
        }, 2500);
      }
    };

    const getCartItemListKey = (cartItem) => {
      if (cartItem.configurable_options?.length > 0) {
        const variantKey = cartItem.configurable_options
          .map(
            (option) =>
              `${option.configurable_product_option_uid}-${option.configurable_product_option_value_uid}`
          )
          .join('-');
        return `${cartItem.product?.merchant_sku}-${variantKey}`;
      }

      return `${cartItem.product?.merchant_sku}`;
    };

    const getRegularPrice = (product) => {
      const regularPrice = cartGetters.getItemPrice(product).regular
        ? cartGetters.getItemPrice(product).regular
        : product.product?.price_range?.minimum_price?.regular_price?.value;
      return formatCurrency(regularPrice, locale, { currency: 'EUR' });
    };

    const getSpecialPrice = (product) => {
      const regularPrice = cartGetters.getItemPrice(product).special
        ? cartGetters.getItemPrice(product).special
        : product.product?.price_range?.minimum_price?.final_price?.value;
      return formatCurrency(regularPrice, locale, { currency: 'EUR' });
    };

    return {
      sendToRemove,
      actionRemoveItem,
      goToCart,
      loading: computed(() => !!loading.value),
      isAuthenticated,
      products: cartItems,
      removeItem,
      updateCartItemQty,
      isCartSidebarOpen,
      notifications,
      visible,
      isLoaderVisible,
      tempProduct,
      toggleCartSidebar,
      goToCheckout,
      totals,
      totalItems,
      cartGetters,
      getAttributes,
      sendNotification,
      productSKU,
      buildProductUrl,
      getCartItemListKey,
      quantitySelectorKey,
      updatingQuantity,
      $gt,
      $gat,
      showErrorMessage,
      locale,
      getRegularPrice,
      getSpecialPrice,
    };
  },
});
</script>

<style lang="scss" scoped>
#cart {
  --sidebar-z-index: 3;
  --overlay-z-index: 3;
  @include for-desktop {
    & > * {
      --sidebar-bottom-padding: var(--spacer-base);
      --sidebar-content-padding: var(--spacer-base);
    }
  }
}

.mobile-content-top {
  display: flex;
}

@include for-mobile {
  .close-icon {
    display: none;
  }
}

.close-icon {
  @include from-desktop-min {
    position: fixed;
  }
  cursor: pointer;
}

.notifications {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -350px;
  z-index: 99999;
  @include for-mobile {
    transform: translate(-50%, -50%);
    display: var(--flex);
  }

  .sf-notification {
    padding: 20px;
    margin: auto;

    .button-wrap {
      margin-top: 15px;
      display: flex;
      column-gap: 15px;
    }
  }
}

.cart-summary {
  margin-top: var(--spacer-xl);
}

.my-cart {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__total-items {
    margin: 0;
  }

  &__total-price {
    --price-font-size: var(--font-size--xl);
    --price-font-weight: var(--font-weight--medium);
    margin: 0 0 var(--spacer-base) 0;
  }
}

.empty-cart {
  --heading-description-margin: 0 0 var(--spacer-xl) 0;
  --heading-title-margin: 0 0 var(--spacer-xl) 0;
  --heading-title-color: var(--c-primary);
  --heading-title-font-weight: var(--font-weight--semibold);
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;

  &__banner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  &__heading {
    padding: 0 var(--spacer-base);
  }

  &__image {
    --image-width: 16rem;
    margin: 0 0 var(--spacer-2xl) 7.5rem;
  }

  @include for-desktop {
    --heading-title-font-size: var(--font-size--xl);
    --heading-title-margin: 0 0 var(--spacer-sm) 0;
  }
}

.collected-product-list {
  flex: 1;
}

.collected-product {
  margin: 0 0 var(--spacer-sm) 0;

  &__properties {
    margin: var(--spacer-xs) 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 2;

    &:first-child {
      margin-bottom: 8px;
    }
  }

  &__actions {
    transition: opacity 150ms ease-in-out;
  }

  &__save,
  &__compare {
    --button-padding: 0;

    &:focus {
      --cp-save-opacity: 1;
      --cp-compare-opacity: 1;
    }
  }

  &__save {
    opacity: var(--cp-save-opacity, 0);
  }

  &__compare {
    opacity: var(--cp-compare-opacity, 0);
  }

  &:hover {
    --cp-save-opacity: 1;
    --cp-compare-opacity: 1;
    @include for-desktop {
      .collected-product__properties {
        display: none;
      }
    }
  }
}

.cart-mini {
  .cart-item-list:not(:last-child) {
    border-bottom: 1px solid var(--c-primary-lightest);
  }
}

.cart-mini {
  .cart-item-list:not(:first-child) {
    padding-top: 28px;
  }
}

.cart-mini {
  .cart-item-list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 28px;

    &:last-child {
      margin-bottom: 0;
    }

    .cart-item-media {
      flex: 32%;
      max-width: 180px;
      max-height: 180px;
      position: relative;
      .cart-item-labels {
        display: flex;
        gap: 0.3125rem;
        position: absolute;
        top: 0.625rem;
        left: 0.625rem;
        @include text-13x16-regular;
        &__label {
          background-color: var(--c-red);
          color: var(--c-white);
          padding: 0.25rem;
          text-transform: uppercase;
        }
      }
    }

    .cart-item-details {
      align-content: space-between;
      display: flex;
      flex-wrap: wrap;
      flex: calc(68% - 26px);
      padding-left: 26px;

      > div,
      > h3 {
        flex: 100%;
        width: 100%;
        height: max-content;
      }

      .cart-item-sku p {
        font-family: var(--font-family-albert-sans);
        @include text-14-regular-light_black;
        letter-spacing: var(--character-spacing-1-54);
        text-transform: uppercase;
        font-weight: 400;
      }

      .cart-item-name {
        @include text-16-regular-black;
        font-weight: 600;
        font-family: var(--font-family-albert-sans);
      }

      .cart-item-configuration {
        display: grid;
      }

      .cart-item-price {
        @include paragraph-regular;

        > p {
          display: inline-block;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 20px;
        }

        .cart-item-price__old,
        p:last-child {
          @include text-20-light-black;
          font-family: var(--font-family-albert-sans);
          text-decoration-line: line-through;
          font-weight: 300;
        }

        .cart-item-price__special,
        p:first-child {
          font-weight: 500;
          @include text-20-black;
          font-family: var(--font-family-albert-sans);
          text-decoration-line: none;
        }

        .cart-item-price__special:empty {
          color: red;
          padding-right: 0;
        }
      }

      .cart-item-remove {
        a {
          align-items: center;
          cursor: pointer;
          display: flex;
          height: 100%;
          justify-content: center;
          margin-left: -1px;
          width: 100%;
        }
      }

      .cart-item-button {
        display: flex;
        flex-wrap: wrap;
        @include for-mobile {
          flex-wrap: nowrap;
          align-items: baseline;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    .cart-item-list {
      .cart-item-details {
        padding-left: 19px;

        .cart-item-sku p {
          font-size: 8px;
          line-height: 16px;
        }

        .cart-item-name {
          font-size: 16px;
          line-height: 24px;
        }

        .cart-item-price > p {
          font-size: 10px;
        }

        .cart-item-button {
          max-width: 300px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.cart-mini {
  position: relative;
  z-index: 100;
}

.cart-mini .sf-header__logo {
  display: flex;
}

.cart-mini .sf-image--wrapper {
  width: 100%;
}

.cart-mini .sf-image--wrapper.empty-cart__image {
  margin: 0;
}

.cart-mini .cart-item-media img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.cart-mini p {
  margin: 0;
}

.cart-mini figure {
  display: flex;
  margin: 0;
  height: 100%;
  width: 100%;
}

.cart-mini .sf-collected-product__actions {
  display: none;
}

.cart-mini .sf-sidebar__bottom {
  padding-left: 55px;
  padding-right: 55px;

  .custom-button {
    margin-bottom: 1rem;
  }
}

.cart-mini .sf-sidebar__top {
  padding: 60px 55px 0;
}

.cart-mini .cart-summary {
  padding-bottom: 20px;
  margin: 0 0 24px;
  display: flex;
  align-items: center;
}

.cart-mini .sf-sidebar__content {
  padding: 0 55px;
}

.cart-mini .sf-collected-product {
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  margin-bottom: 24px;
}

.cart-mini .sf-collected-product__quantity-selector {
  width: 100%;
  @include for-mobile {
    width: 95%;
  }
}

.cart-mini .sf-collected-product__quantity-wrapper {
  position: relative;
  padding: 0;
  flex: calc(100% - 55px);
}

.cart-mini .cart-item-remove {
  flex: 55px;
}

.cart-mini .sf-collected-product__quantity-selector button {
  font-weight: 300;
  flex: 35%;
  padding: 10px 0 0;
  font-size: 18px;
  line-height: 43px;
}

.cart-mini .sf-collected-product__quantity-selector .sf-input {
  flex: 30%;
  display: flex;
  align-items: center;
  height: 100%;
  border-right: 1px solid var(--c-primary-lightest);
  border-left: 1px solid var(--c-primary-lightest);
}

.cart-mini .sf-icon {
  margin: auto;
}

.cart-mini .sf-input {
  width: 45px;
  --input-height: 45px;
  padding: 0 5px;
}

.cart-mini .sf-sidebar__top .sf-property__name:after {
  content: '';
}

.cart-mini .sf-sidebar__top .sf-property__value {
  @include text-13-regular-white-bold;
  font-family: var(--font-family-plus-jakarta-sans);
  border: 1px solid var(--c-primary);
  background-color: var(--c-primary);
  border-radius: 100%;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  font-weight: 400;
}

.cart-mini .sf-sidebar__top .sf-property__name {
  @include h3;
  font-family: var(--font-family-plus-jakarta-sans);
  color: var(--c-primary);
  text-transform: none;
}

.cart-mini .sf-sidebar__bottom .sf-button.color-secondary,
.cart-mini .sf-sidebar__bottom .sf-button.color-primary {
  border: 1px solid #707070;
  font-size: 12px;
  font-weight: 700;
  height: 55px;
  letter-spacing: 1px;
  line-height: 55px;
  padding: 0;
}

.cart-mini ~ .notifications .sf-notification__message {
  color: #fff;
  padding: 0 30px 0 10px;
}

.cart-mini .notifications .sf-button {
  padding: 0 30px;
  height: 50px;
  line-height: 50px;
}

.cart-mini .sf-sidebar__bottom .sf-button.color-secondary:hover,
.cart-mini .sf-sidebar__bottom .sf-button.color-secondary:active,
.cart-mini .sf-sidebar__bottom .sf-button.color-secondary:focus,
.cart-mini .sf-sidebar__bottom .sf-button.color-primary:hover,
.cart-mini .sf-sidebar__bottom .sf-button.color-primary:active,
.cart-mini .sf-sidebar__bottom .sf-button.color-primary:focus,
.cart-mini .notifications .sf-button:hover,
.cart-mini .notifications .sf-button:active,
.cart-mini .notifications .sf-button:focus {
  text-decoration-line: none;
}

.cart-mini .cart-item-list .sf-property {
  padding-right: 15px;
  display: inline-block;
}

.cart-mini .cart-item-list .sf-property__name,
.cart-mini .cart-item-list .sf-property__value {
  font-family: var(--font-family-albert-sans);
  @include text-14-regular-light_black;
  letter-spacing: var(--character-spacing-1-54);
  text-transform: uppercase;
}

.cart-mini .cart-item-list .sf-property__name {
  font-weight: bold;
}

.cart-mini .cart-item-list .sf-property__value {
  font-weight: 400;
}

.cart-mini .sf-input input {
  font-size: 16px;
  font-weight: 500;
}

.cart-mini .sf-input__error-message {
  display: block;
  left: -72px;
  min-width: 210px;
  position: absolute;
  width: 100%;
}

.cart-mini .sf-input input:focus-visible {
  outline: 0;
  border: 0;
}

.cart-mini .empty-cart__heading .sf-heading__title.h2 {
  font-size: 30px;
  text-align: center;
}

.cart-mini .notifications .sf-icon {
  margin-right: 20px;
}

.cart-mini .notifications {
  .sf-icon svg {
    --icon-color: #212a24;
  }

  .sf-notification__title {
    display: var(--hidden);
  }
}

.cart-mini .sf-notification__message,
.cart-mini .sf-notification__title {
  color: #212a24;
}

.cart-mini .sf-sidebar__aside {
  height: 100%;
  background-color: var(--c-light-grey);
}

.sf-badge--number {
  background-color: #fdcfc1;
  height: 17px;
  font-size: 9px;
  color: #222a24;
  font-weight: 700;
  line-height: 19px;
  position: absolute;
  right: -8px;
  top: -5px;
}

.cart-mini .button-go-to-cart {
  margin-top: 15px;
}

@media (min-width: 1024px) {
  .cart-mini .sf-sidebar__aside {
    width: 570px;
  }

  .cart-mini .sf-collected-product {
    max-width: 100%;
  }

  .cart-mini .sf-collected-product__configuration {
    justify-content: flex-start;
    padding-bottom: 50px;
  }

  .cart-mini .close-icon {
    right: 55px;
    top: 60px;
  }

  .cart-mini .header-mini-cart {
    display: none;
  }

  .cart-mini .sf-sidebar__bottom .sf-button.color-primary {
    max-width: 439px;
  }
}

@media (max-width: 1023px) {
  .cart-mini .sf-sidebar__content,
  .cart-mini .sf-sidebar__top,
  .cart-mini .sf-sidebar__bottom {
    padding-left: 23px;
    padding-right: 23px;
  }

  .cart-mini .cart-item-content div {
    height: max-content;
    line-height: 0;
  }

  .cart-mini .cart-item-list .sf-property__value {
    font-weight: 500;
  }

  .cart-mini .sf-collected-product__quantity-selector button {
    font-size: 20px;
    flex: 30%;
    padding: 6px 0 0;
  }

  .cart-mini .sf-collected-product__quantity-selector .sf-input {
    flex: 40%;
  }

  .cart-mini .sf-sidebar__top .cart-summary.desktop-only {
    display: flex !important;
  }

  .cart-mini .cart-summary {
    border-bottom: 0;
    margin: 0;
    padding-bottom: 30px;
  }

  .cart-mini .sf-sidebar__top {
    padding-bottom: 0;
    padding-top: 25px;
  }

  .cart-mini .close-icon {
    display: block;
    top: 15px;
    right: 20px;
    @include to-tablet-max {
      margin-left: auto;
    }
  }

  .cart-mini .sf-bar__icon {
    display: none;
  }

  .cart-mini .sf-bar {
    display: none !important;
  }

  .cart-mini .cart-item-list .sf-property {
    padding-right: 0;
    width: 100%;
  }

  .cart-mini .cart-item-list .sf-property > span {
    font-size: 10px;
    line-height: 14px;
  }

  .cart-mini .sf-collected-product__quantity-wrapper {
    flex: calc(100% - 45px);
  }

  .cart-mini .cart-item-remove {
    flex: 45px;
  }

  .cart-mini .sf-input input {
    font-size: 14px;
    height: 45px;
    line-height: 45px;
  }

  .cart-mini .sf-sidebar__bottom .sf-button.color-secondary,
  .cart-mini .sf-sidebar__bottom .sf-button.color-primary {
    font-size: 10px;
    letter-spacing: 0px;
    height: 55px;
    line-height: 55px;
  }

  .cart-mini .notifications {
    left: 50%;
    margin: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .cart-mini .sf-header__item_right {
    display: none;
  }

  .cart-mini .header-mini-cart {
    left: 0;
    position: absolute;
    top: -14px;
  }

  .cart-mini .header-mini-cart .sf-header-custom_container {
    position: absolute;
    top: -47px;
  }

  .cart-mini #overlay_menu,
  .cart-mini #mega_menu {
    top: 0;
  }
}

@media (max-width: 767px) {
  .cart-mini .header-mini-cart {
    top: -13px;
  }

  .cart-mini .my-cart .cart-item-list {
    flex-wrap: nowrap;
  }

  .cart-mini .cart-item-list .cart-item-media {
    min-width: 139px;
  }

  .cart-mini .sf-sidebar__top .sf-property__name,
  .cart-mini .sf-sidebar__top .sf-property__value {
    font-weight: 500;
  }
}
</style>
